import localForage from 'localforage';
import _ from 'lodash'
import { postToBaseInfoServer,postToBaseInfo1Server } from './common';
/*-------------------------------类目------------------------------*/
/**
 * 类目列表
 */
export async function catList(obj) {
    return await postToBaseInfoServer('/admin/cat/list', obj);
}
/**
 * 新增类目
 */
export async function catAdd(obj) {
    return await postToBaseInfoServer('/admin/cat/add', obj);
}
/**
 * 修改类目
 */
export async function catEdit(obj) {
    return await postToBaseInfoServer('/admin/cat/edit', obj);
}
/**
 * 移动类目
 */
export async function catMove(obj) {
    return await postToBaseInfoServer('/admin/cat/move', obj);
}
/**
 * 删除类目
 */
export async function catDel(obj) {
    return await postToBaseInfoServer('/admin/cat/del', obj);
}
/**
 * 获得类目详情
 */
export async function catGetId(id) {
    return await postToBaseInfoServer(`/admin/cat/get/${id}`);
}
/*-------------------------------sku类目属性------------------------------*/
/**
 * sku类目属性列表
 */
export async function catAttrList(id) {
    return await postToBaseInfoServer(`/admin/cat/attr/list/${id}`);
}
/**
 * sku类目属性详情
 */
export async function catAttrId(id) {
    return await postToBaseInfoServer(`/admin/cat/attr/get/${id}`);
}
/**
 * 新增sku类目属性
 */
export async function catAttrAdd(obj) {
    return await postToBaseInfoServer('/admin/cat/attr/add', obj);
}
/**
 * 编辑sku类目属性
 */
export async function catAttrEdit(obj) {
    return await postToBaseInfoServer('/admin/cat/attr/edit', obj);
}
/**
 * 删除sku类目属性
 */
export async function catAttrDel(id) {
    return await postToBaseInfoServer(`/admin/cat/attr/del`,{id:id});
}
/**
 * sku类目属性值列表
 */
export async function catAttrValueList(attrid) {
    return await postToBaseInfoServer(`/admin/cat/attr/value/list/${attrid}`);
}
/**
 * sku类目属性值列表排序
 */
export async function catAttrValueSort(obj) {
    return await postToBaseInfoServer(`/admin/cat/attr/value/sort`,obj);
}
/**
 * 新增sku类目属性值
 */
export async function catAttrValueAdd(obj) {
    return await postToBaseInfoServer('/admin/cat/attr/value/add', obj);
}
/**
 * 编辑sku类目属性值
 */
export async function catAttrValueEdit(obj) {
    return await postToBaseInfoServer('/admin/cat/attr/value/edit', obj);
}
/**
 * 删除sku类目属性值
 */
export async function catAttrValueDel(id) {
    return await postToBaseInfoServer(`/admin/cat/attr/value/del`,{id:id});
}
/*-------------------------------spu类目属性------------------------------*/
/**
 * spu类目属性列表
 */
export async function catSpuAttrList(id) {
    return await postToBaseInfoServer(`/admin/cat/spu/attr/list/${id}`);
}
/**
 * spu类目属性详情
 */
export async function catSpuAttrId(id) {
    return await postToBaseInfoServer(`/admin/cat/spu/attr/get/${id}`);
}
/**
 * 新增spu类目属性
 */
export async function catSpuAttrAdd(obj) {
    return await postToBaseInfoServer('/admin/cat/spu/attr/add', obj);
}
/**
 * 编辑spu类目属性
 */
export async function catSpuAttrEdit(obj) {
    return await postToBaseInfoServer('/admin/cat/spu/attr/edit', obj);
}
/**
 * 删除spu类目属性
 */
export async function catSpuAttrDel(id) {
    return await postToBaseInfoServer(`/admin/cat/spu/attr/del`,{id:id});
}
/**
 * spu类目属性值列表
 */
export async function catSpuAttrValueList(attrid) {
    return await postToBaseInfoServer(`/admin/cat/spu/attr/value/list/${attrid}`);
}
/**
 * spu类目属性值列表排序
 */
export async function catSpuAttrValueSort(obj) {
    return await postToBaseInfoServer(`/admin/cat/spu/attr/value/sort`,obj);
}
/**
 * 新增spu类目属性值
 */
export async function catSpuAttrValueAdd(obj) {
    return await postToBaseInfoServer('/admin/cat/spu/attr/value/add', obj);
}
/**
 * 编辑spu类目属性值
 */
export async function catSpuAttrValueEdit(obj) {
    return await postToBaseInfoServer('/admin/cat/spu/attr/value/edit', obj);
}
/**
 * 删除spu类目属性值
 */
export async function catSpuAttrValueDel(id) {
    return await postToBaseInfoServer(`/admin/cat/spu/attr/value/del`,{id:id});
}
/*-------------------------------风格属性------------------------------*/
/**
 * 风格属性列表
 */
export async function styleList(obj) {
    return await postToBaseInfoServer('/admin/style/list', obj);
}
/**
 * 风格属性列表排序
 */
export async function styleSort(obj) {
    return await postToBaseInfoServer('/admin/style/sort', obj);
}
/**
 * 新增风格属性
 */
export async function styleAdd(obj) {
    return await postToBaseInfoServer('/admin/style/add', obj);
}
/**
 * 编辑风格属性
 */
export async function styleEdit(obj) {
    return await postToBaseInfoServer('/admin/style/edit', obj);
}
/**
 * 删除风格属性
 */
export async function styleDel(id) {
    return await postToBaseInfoServer(`/admin/style/del/${id}`);
}
/*-------------------------------颜色属性------------------------------*/
/**
 * 颜色属性列表
 */
export async function colorList(obj) {
    return await postToBaseInfoServer('/admin/color/list', obj);
}
/**
 * 颜色属性列表排序
 */
export async function colorSort(obj) {
    return await postToBaseInfoServer('/admin/color/sort', obj);
}
/**
 * 新增颜色属性
 */
export async function colorAdd(obj) {
    return await postToBaseInfoServer('/admin/color/add', obj);
}
/**
 * 编辑颜色属性
 */
export async function colorEdit(obj) {
    return await postToBaseInfoServer('/admin/color/edit', obj);
}
/**
 * 删除颜色属性
 */
export async function colorDel(id) {
    return await postToBaseInfoServer(`/admin/color/del/${id}`);
}
/*-------------------------------材质属性------------------------------*/
/**
 * 材质属性列表
 */
export async function materialList(obj) {
    return await postToBaseInfoServer('/admin/material/list', obj);
}
/**
 * 材质属性列表排序
 */
export async function materialSort(obj) {
    return await postToBaseInfoServer('/admin/material/sort', obj);
}
/**
 * 新增材质属性
 */
export async function materialAdd(obj) {
    return await postToBaseInfoServer('/admin/material/add', obj);
}
/**
 * 编辑材质属性
 */
export async function materialEdit(obj) {
    return await postToBaseInfoServer('/admin/material/edit', obj);
}
/**
 * 删除材质属性
 */
export async function materialDel(id) {
    return await postToBaseInfoServer(`/admin/material/del/${id}`);
}
/*-------------------------------资质字典------------------------------*/
/**
 * 资质字典列表
 */
export async function aptitudeList(obj) {
    return await postToBaseInfo1Server('/dict-aptitude/list-aptitude', obj);
}
/**
 *  修改类目
 */
export async function editAptitude(obj) {
    return await postToBaseInfo1Server('/dict-aptitude/update-aptitude-field', obj);
}
/**
 * 获取工厂工艺能力列表
 */
export async function listAbility(obj) {
    return await postToBaseInfo1Server('/dict-factory-ability/list-ability', obj);
}
/**
 * 增加工厂工艺能力
 */
export async function addAbility(obj) {
    return await postToBaseInfo1Server('/dict-factory-ability/add-ability', obj);
}
/**
 * 编辑工厂工艺能力
 */
export async function editAbility(obj) {
    return await postToBaseInfo1Server('/dict-factory-ability/update-ability', obj);
}
/**
 * 删除工厂工艺能力
 */
export async function delAbility(obj) {
    return await postToBaseInfo1Server('/dict-factory-ability/del-ability', obj);
}
/**
 * 获取工厂工艺能力指标列表
 */
export async function listQuota(obj) {
    return await postToBaseInfo1Server('/dict-factory-ability/list-quota', obj);
}
/**
 * 增加工厂工艺能力指标
 */
export async function addQuota(obj) {
    return await postToBaseInfo1Server('/dict-factory-ability/add-quota', obj);
}
/**
 * 修改工厂工艺能力指标
 */
export async function editQuota(obj) {
    return await postToBaseInfo1Server('/dict-factory-ability/update-quota', obj);
}
/**
 * 删除工厂工艺能力指标
 */
export async function delQuota(obj) {
    return await postToBaseInfo1Server('/dict-factory-ability/del-quota', obj);
}
/*------------------------------------地址字典------------------------------------*/
/**
 * 获取工厂所在区域字典
 */
export async function getFactoryZones(){
    return await postToBaseInfo1Server('/dict-factory-zone/list-zone');
}
/**
 * 增加工厂所在区域字典
 */
export async function addFactoryZones(obj){
    return await postToBaseInfo1Server('/dict-factory-zone/add-zone',obj);
}
/**
 * 修改工厂所在区域字典
 */
export async function editFactoryZones(obj){
    return await postToBaseInfo1Server('/dict-factory-zone/update-zone',obj);
}



